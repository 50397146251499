.main {
	&-pick {
		// overflow: hidden
		// min-height: 643px
		// background-color: #545454
		color: white;
		a {
			text-decoration: none; }
		.sidebar {
			background: #dcdcdc;
			min-height: 100%; }
		&-title {
			font-size: 36px;
			font-weight: 700;
			margin-bottom: 15px;
			@include r(1260) {
				font-size: 48px; } }
		&-desc {
			font-size: 16px;
			line-height: 24px; }
		&-content {
			padding-left: 25px;
			padding-top: 15px;
			line-height: 1.1;
			height: 100%;
			z-index: 1;
			position: relative; } }
	&-advantages {
		padding-top: 53px;
		padding-bottom: 10px;
		&-title {
			color: #0f0f0f;
			font-size: 36px;
			font-weight: 400;
			text-align: center;
			line-height: 1.1;
			margin-bottom: 26px; }
		&-list {
			margin-bottom: 65px; }
		&-item {
			text-align: center;
			&-ico {
				min-height: 150px;
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				-webkit-justify-content: center;
				justify-content: center; }
			&-name {
				color: #0f0f0f;
				font-size: 20px;
				font-weight: 400;
				line-height: 27px; } }
		.content {
			text-align: center; } }
	&-buy {
		min-height: 603px;
		padding-top: 60px;
		padding-bottom: 88px;
		background-color: #c4c4c4;
		background-image: url("../img/main-buy-bg.png");
		background-position: bottom left;
		background-repeat: no-repeat;
		&-title {
			color: #0f0f0f;
			font-size: 30px;
			font-weight: 400;
			text-align: center;
			margin-bottom: 80px; }
		&-list {
			text-align: center;
			display: grid;
			grid-template-columns: 50% 50%;
			grid-row-gap: 38px;
			a {
				color: black;
				font-size: 18px;
				font-weight: 400; } } }
	&-content {
		padding-top: 49px;
		padding-bottom: 15px;
		&-title {
			color: #0f0f0f;
			font-size: 30px;
			font-weight: 400;
			margin-bottom: 29px;
			text-align: center; } } }
.main-advantages-item-ico {
	img {
		max-width: 100%; } }
.catalog-list--category {
	.catalog-list-item {
		display: flex !important;
		justify-content: center;
		text-align: center;
		border: 1px solid #f1f1f1;
		text-decoration: none;
		max-width: 100% !important;
		width: 100% !important;
		height: calc(100% - 30px);
		margin-bottom: 30px;
		transition: transform 400ms ease;
		&:hover {
			transform: scale(1.05); } }
	.catalog-list-item__photo {
		width: 50% !important;
		min-width: 50% !important;
		flex-basis: 50% !important;
		height: auto !important;
		overflow: hidden;
		text-align: center;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex !important;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
			max-height: 100%;
			display: inline-block; } }
	.catalog-list-item__name {
		background-color: #f1f1f1;
		display: flex !important;
		-webkit-align-items: center;
		align-items: center;
		min-height: auto;
		height: auto;
		flex-grow: 1;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 15px;
		text-align: center;
		color: #0f0f0f;
		font-size: 14px !important;
		line-height: 20px;
		font-weight: 600;
		text-decoration: none; } }

.catalog {
	&-list {
		margin-bottom: 49px;
		&-item {
			display: inline-block;
			text-align: center;
			border: 1px solid #f1f1f1;
			text-decoration: none;
			max-width: 100%;
			width: 100%;
			height: calc(100% - 30px);
			margin-bottom: 30px;
			transition: transform 400ms ease;
			&:hover {
				transform: scale(1.05); }
			&__photo {
				height: 240px;
				overflow: hidden;
				text-align: center;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					max-width: 100%;
					max-height: 100%;
					display: inline-block; } }
			&__name {
				background-color: #f1f1f1;
				min-height: 111px;
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				height: calc(100% - 240px);
				-webkit-justify-content: center;
				justify-content: center;
				padding: 15px;
				text-align: left;
				color: #0f0f0f;
				font-size: 17px;
				font-weight: 600;
				text-decoration: none; } } } }
.product-card {
	margin-top: 39px;
	margin-bottom: 105px;
	&__photo {
		height: 350px;
		text-align: center;
		overflow: hidden;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		img {
			max-width: 100%;
			display: inline-block;
			max-height: 100%; } }
	&__title {
		color: #0f0f0f;
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 13px; }
	&__stock {
		color: #018178;
		font-size: 14px;
		font-weight: 400; }
	&__delivery {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 20px;
		line-height: 1.1; }
	&__info {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 50px; }
	&__total {
		span {
			color: #018178;
			font-size: 24px;
			margin-right: 8px; }
		color: #0f0f0f;
		font-size: 14px;
		.btn {
			padding: 2px 40px;
			margin-top: 8px;
			text-decoration: none; } }
	&-delivery {
		min-height: 336px;
		background-color: #f6f6f6;
		margin-top: -10px;
		padding: 20px;
		padding-top: 20px; }
	&-description__title {
		@include h3;
		font-weight: 700;
		padding: 0 0px 20px 20px; } }

.header-mobile-toggle {
	display: none;
	margin-top: 30px;
	background: #000;
	position: relative;
	color: #FFF;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 20px;
	margin-left: -15px;
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px;
	z-index: 10;
	.header-mobile-list {
		position: absolute;
		display: none;
		top: 100%;
		left: 0;
		z-index: 1;
		background: #000;
		width: 100%;
		padding: 10px 15px 20px;
		a {
			color: #FFF;
			text-decoration: none; } }
	&.active {
		background: #464646;
		.header-mobile-list {
			display: block; } } }

.catalog-list-item.product-list-item.product-list-item--row {
	.catalog-list-item__name {
		height: auto; } }


.product-card-slider {
	max-height: 440px;
	margin-bottom: 40px;
	max-width: 80%;
	flex-basis: 80%;
	width: 100%;
	img {
		max-height: 400px;
		width: auto !important;
		display: block !important;
		max-width: 100%;
		margin: 0 auto; } }
.product-card-slider-nav {
	margin: 0 auto;
	.slick-slide {
		margin: 0 12px; }
	.slick-list {
		margin: 0 -12px; }
	.product-card-slider-nav-image {
		width: 70px !important;
		height: 70px;
		min-width: 70px;
		// border: 1px solid rgba(96, 116, 136, 0.2);
		text-align: center;
		display: flex !important;
		align-items: center;
		justify-content: center;
		img {
			max-height: 100%;
			max-width: 99%; } } }
.product-card-thumbnails {
	max-width: 20%;
	flex-basis: 20%;
	width: 100%;
	margin: 0 auto; }

