@mixin h1 {
	font-size: 26px;
	line-height: 1.1; }
@mixin h2 {
	font-size: 22px; }
@mixin h3 {
	font-size: 19px; }

h1, .h1,
h2, .h2,
h3, .h3 {
	color: #0f0f0f;
	font-weight: 400; }

h1, .h1 {
	@include h1;
	margin-bottom: 28px; }

h2, .h2 {
	@include h2;
	margin-bottom: 20px; }
h3, .h3 {
	@include h3;
	margin-bottom: 10px; }
