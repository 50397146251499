body {
	// background: #FFF
	// +css-lock(16, 50, 600, 1000)
	color: #555;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	flex-direction: column;
	min-height: 100vh; }
main {
	flex-grow: 1;
	min-height: 40vh; }

a {
	color: #dc4040;
	line-height: 27px;
	transition: .2s;
	&:hover {
		color: lighten(#dc4040, 7%); } }
.header {
	min-height: 163px;
	background-color: #d2d2d2;
	padding: 36px 0 24px;
	&-logo {
		a {
			display: block;
			max-width: 267px;
			@include r(991) {
				display: inline-block; } }
		img {
			max-width: 100%; }
		&-title {
			color: #000;
			font-size: 16px;
			padding-left: 9px;
			margin-top: -9px;
			@include r(991) {
				display: none; } }
		@include rmin(1024) {
			-ms-flex: 0 0 30%;
			flex: 0 0 30%;
			max-width: 30%; } }
	&-search {
		@include rmin(1024) {
			-ms-flex: 0 0 45%;
			flex: 0 0 45%;
			max-width: 45%; }
		form {
			position: relative; }
		input[type="search"] {
			max-width: 100%;
			width: 100%;
			height: 49px;
			border: 1px solid #dc4040;
			box-shadow: inset 0.5px 0.9px 5px rgba(0, 0, 0, 0.54);
			color: #555;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			-webkit-appearance: textfield;
			padding: 10px 15px;
			transition: .4s;
			background-color: white;
			&:focus {
				border: 1px solid #000; }

			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button {
				-webkit-appearance: none; } }
		input[type="submit"] {
			width: 32px;
			position: absolute;
			border: none;
			box-shadow: none;
			font-size: 0;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			height: 32px;
			background: url(../img/search.svg) center/ contain no-repeat;
			background-color: #fff;
			transition: opacity .3s ease;
			&:hover {
				opacity: 0.7; } } }
	&-contacts {
		text-align: right;
		@include rmin(1024) {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%; } }
	&-nav {
		ul {
			display: flex;
			@include r(991) {
				flex-wrap: wrap; }
			a {
				color: black;
				text-decoration: none;
				margin-right: 46px;
				&:hover {
					color: #555; } }
			li {
				&:last-child {
					a {
						margin-right: 0; } } } } } }
.footer {
	min-height: 366px;
	background-color: #161616;
	box-shadow: 0.5px 0.9px 16.6px 1.4px rgba(0, 0, 0, 0.28);
	color: #FFF;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	padding: 20px 0;
	a {
		color: white;
		font-size: 18px;
		font-weight: 400;
		line-height: 36px;
		text-decoration: none;
		&:hover {
			color: darken(#fff, 25%); } }
	&-logo {
		text-align: right;
		display: inline-block;
		margin-bottom: 20px;
		img {
			max-width: 100%; } }
	&-right {
		text-align: right;
		padding-top: 10px; }
	&-contacts {
		a {
			font-size: 18px;
			line-height: 24px;
			display: inline-block;
			margin-top: 20px; } } }
.layout {
	&-col {
		&-left {
			@include rmin(1260) {
				-ms-flex: 0 0 28%;
				flex: 0 0 28%;
				max-width: 28%; } }
		&-right {
			@include rmin(1260) {
				-ms-flex: 0 0 72%;
				flex: 0 0 72%;
				max-width: 72%; } } } }
.main-pick {
	&-content {
		@include r(991) {
			margin-bottom: 15px;
			padding-left: 0; }
		&-promo {
			padding: 40px 25px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex !important;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-end;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			min-height: 426px;
			margin-bottom: 2%;
			@include r(1023) {
				// min-height: auto
				margin-bottom: 15px; }
			// &-slider
 } }			// 	min-height: 80%;
	&-sta {
		background: #2c2928;
		padding: 20px 25px;
		border-radius: 18px;
		color: #fff;
		min-height: 13%;
		&-text {
			@include r(767) {
				margin-bottom: 15px; } }
		@include r(1023) {
			min-height: auto; } } }
.sidebar {
	margin-left: -20px;
	padding-left: 20px;
	padding-top: 34px;
	padding-bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #f1f1f1;
	a {
		color: #343434;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		text-decoration: none;
		display: inline-block;
		margin-bottom: 24px;
		&:hover {
			color: #dc4040; } }
	&-allcategory {
		height: 60px;
		background-color: #c4c4c4;
		box-shadow: 3px 5.2px 9.5px 0.5px rgba(0, 0, 0, 0.5);
		display: flex !important;
		-webkit-align-items: center;
		align-items: center;
		margin-bottom: 0 !important;
		margin-left: -35px;
		padding-left: 35px;
		margin-top: 8px;
		position: relative;
		overflow: hidden;
		z-index: 1;
		&:before {
			content: '';
			position: absolute;
			left: -100%;
			top: 0;
			height: 100%;
			width: 0%;
			background: #dc4040;
			display: block;
			z-index: -1;
			opacity: 0;
			transition: all .4s ease; }
		&:hover {
			color: #FFF !important;
			&:before {
				left: 0;
				opacity: 1;
				width: 100%; } } }
	&-form {
		margin-left: -20px;
		padding-left: 20px;
		background: url("../img/form-bg.png") center no-repeat;
		margin-top: 6px;
		min-height: 479px;
		background-color: #d2d2d2;
		padding-top: 20px;
		padding-right: 20px;
		margin-bottom: 150px;
		&-title {
			color: #030303;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 6px; }
		&-desc {
			color: #030303;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 20px; }
		form {
			.submit-text {
				color: white;
				font-size: 14px;
				font-weight: 400;
				line-height: 18px; }
			div {
				margin-bottom: 20px; }
			input {
				height: 32px;
				padding: 5px 15px;
				background-color: white;
				width: 100%;
				color: #000;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px; } } } }



.btn {
	display: inline-block;
	background-color: #dc4040;
	color: #FFF;
	border-radius: 15px;
	padding: 15px 30px;
	text-align: center;
	&:hover {
		color: #FFF;
		background-color: lighten(#dc4040, 7%); } }

.content {
	p {
		color: #0f0f0f;
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 25px; }
	ul {
		li {
			margin: 0;
			margin-bottom: 1em;
			padding-left: 1.5em;
			position: relative;

			&:after {
				content: '';
				height: .5em;
				width: .5em;
				background: #D2153A;
				display: block;
				position: absolute;
				top: .35em;
				left: 0; } } }
	ol {
		list-style: none;
		counter-reset: decimal;
		li {
			counter-increment: decimal;
			padding-left: 1.5em;
			margin: 0;
			margin-bottom: 1em;
			position: relative;
			&::before {
				content: counter(decimal) ".";
				display: inline-block;
				color: #A12124;
				position: absolute;
				top: 0;
				left: 0;
				text-align: center;
				margin-right: 5px; } } }

	ol ol {
		li::before {
			background: #A12124; }
		ol li::before {
			background: #A12124; } } }

.product-card-about {
	.content {
		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 20px; }
		.h2 {
			font-size: 22px;
			font-weight: 400;
			margin-bottom: 12px; } } }

.breadcrumbs {
	margin-bottom: 15px;
	a {
		color: #dc4040;
		border-bottom: 1px solid #dc4040;
		transition: border-bottom 100ms ease;
		position: relative;
		text-decoration: none;
		margin-right: 10px;
		&:after {
			content: '/';
			display: inline-block;
			margin-left: 5px;
			position: absolute;
			color: #0f0f0f;
			top: -4px;
			right: -11px; }
		&:hover {
			border-bottom: 1px solid transparent; }
		&:last-child {
			color: #0f0f0f;
			cursor: default;
			text-decoration: none;
			border-bottom: none;
			&:after {
				content: none; } } } }
.sidebar-layout {
	padding-left: 43px;
	padding-top: 38px;
	padding-bottom: 58px;
	&-title {
		@include h1;
		margin-bottom: 20px; }
	&-fulllwidth {
		padding-left: 0; } }
.menu-down {
	min-height: 49px;
	background-color: darkgrey;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	&-list {
		ul {
			display: flex;
			li {
				margin-right: 40px;
				a {
					color: black;
					text-decoration: none;
					font-size: 16px;
					font-weight: 400;
					&:hover {
						color: #f1f1f1; } } } } } }

.sidebar-layout-view {
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: flex-end;
		justify-content: flex-end;
		color: #0f0f0f;
		font-size: 18px;
		font-weight: 400;
		a {
			margin-left: 10px;
			text-decoration: none;
			transition: all .3s ease;
			opacity: 0.5;
			&:hover {
				opacity: 0.8; }
			&.active {
				opacity: 1; }
			img {
				width: 23px;
				height: 20px; } } }
.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  margin: 0 auto;
  &-wrapper {
  	display: flex;
  	-webkit-align-items: center;
  	align-items: center;
  	-webkit-justify-content: center;
  	justify-content: center;
  	margin-bottom: 99px;
  	-webkit-flex-wrap: wrap;
  	-ms-flex-wrap: wrap;
  	flex-wrap: wrap; }
  li {
    display: inline;
    text-align: center; }
  a {
    float: left;
    display: block;
    font-size: 14px;
    text-decoration: none;
    padding: 5px 12px;
    color: #fff;
    margin-left: -1px;
    border: 1px solid transparent;
    line-height: 1.5;
    &.active {
      cursor: default; }
    &:active {
      outline: none; } } }

.pagination {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  a {
    border-color: #ddd;
    color: #999;
    background: #fff;
    padding: 10px 15px;
    &:hover {
      color: #dc4040;
      background-color: #eee; }
    &.prev {
      -moz-border-radius: 50px 0 0 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px 0 0 50px;
      width: 40px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 100%;
        top: 0;
        right: 0; } }
    &.next {
      -moz-border-radius: 0 50px 50px 0;
      -webkit-border-radius: 0;
      border-radius: 0 50px 50px 0;
      width: 40px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 100%;
        top: 0;
        left: 0; } }
    &.active {
      border-color: #bbb;
      background: #fff;
      color: #dc4040;
      -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset;
      -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset; } } }

.product-list-item {
	border-radius: 0 0 15px 15px;
	display: block;
	overflow: hidden;
	border: 1px solid #c8c8c8 !important;
	.catalog-list-item__name {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		color: #0f0f0f;
		font-size: 14px;
		font-weight: 400;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		background-color: #f8f8f8;
		.product-card__total {
			text-align: left;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			span {
				font-size: 20px; } }
		.product-card__delivery {
			margin-bottom: 10px; }
		.btn {
			display: block;
			justify-content: center;
			margin: 0 auto;
			text-align: center;
			margin-top: 10px;
			/* width: 100%; */
			max-width: 140px; } } }
.product-list-item--row {
	width: 100%;
	display: flex !important;
	.catalog-list-item__photo {
		max-width: 180px;
		width: 100%; }
	.catalog-list-item__name {
		text-align: left;
		width: 100%;
		.row {
			width: 100%; }
		.product-card__total {
			text-align: right; }
		.btn {
			padding: 2px 20px; } } }
.viewall {
	width: 100%;
	text-align: center;
	margin-top: 30px;
	.btn {
		height: 31px;
		background-color: #c4c4c4;
		display: inline-flex;
		-webkit-align-items: center;
		align-items: center;
		&:hover {
			background-color: #dc4040; } }
	border-radius: 15px; }
#map {
	height: 820px;
	width: calc(100% + 40px);
	margin-left: -20px;
	margin-right: -20px;
	@include r(1080) {

		height: 520px;
		width: 100%;
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px; }
	@include r(767) {
		height: 320px;
		width: 100%;
		margin-right: 0;
		margin-left: 0; } }
.contacts-map {
	margin: 30px 0 80px;
	.container {
		position: relative; }
	&-info {
		position: absolute;
		// box-shadow: 0 0 20px -3px rgba(0,0,0,0.5)
		background: #FFF;
		top: 25px;
		left: 20px;
		z-index: 1;
		padding: 30px 20px;
		max-width: 40%;

		@include r(767) {
			position: static;
			width: 100%;
			max-width: 100%;
			box-shadow: none;
			padding: 0;
			margin-bottom: 20px; }

		div {
			margin-bottom: 40px;
			@include r(1080) {
				margin-bottom: 20px; }
			&:last-child {
				margin-bottom: 0; }
			b {
				display: block;
				font-weight: 700; } } } }
.easy-autocomplete {
	width: 100% !important;
	max-width: 100%; }
.search-img {
	width: 10%;
	img {
		max-width: 100%; }
	@include r(1199) {
		display: none; } }
.search-name {
	width: 65%;
	padding: 0 10px; }
.search-cost {
	width: 25%;
	font-weight: 700 !important; }

.eac-item a {
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 4px 12px; }
.easy-autocomplete-container li {
	border-bottom: 1px solid #dc4040;
	padding: 0; }
.search-head, .header-search-wrapper form {
	width: 100% !important; }

.white-popup {
	position: relative;
	max-width: 496px;
	width: 100%;
	margin: 0 auto;
	background-color: white;
	border-radius: 10px;
	.h5 {
		margin-bottom: 20px; }
	.mfp-close {
		font-size: 42px !important;
		color: #DC4040 !important; }
	&-header {
		background: #dc4040;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		min-height: 171px;
		border-radius: 10px 10px 0 0;
		img {
			width: 168px;
			height: 101px;
			display: block;
			margin: 0 auto; } }
	&-main {
		padding: 20px 47px 50px;
		text-align: center;
		.form-policy {
			font-size: 14px; } }
	.h3 {
		margin-bottom: 12px; }
	button.btn.btn--second {
		margin: 0 auto; } }
#callback-form .mfp-close {
	color: #FFF; }
#thanks-form {
	padding: 50px;
	position: relative; }
.text-input {
	margin-bottom: 20px;
	input, textarea {
		width: 100%;
		padding: 7px 10px;
		height: 40px;
		background-color: white;
		border: 1px solid #dc4040;
		border-radius: 10px;
		color: #000;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		@include placeholder-color(#9f9f9f); }
	textarea {
		resize: none;
		height: 158px; }
	input.error, textarea.error {
		border: 1px solid #c30606; }

	label.error {
		display: block;
		color: #c30606;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px; } }
@include r(1199) {
	.footer a, .footer {
		font-size: 16px; }
	.product-card-about .content {
		margin-bottom: 60px; } }

@include r(1023) {
	.footer a, .footer {
		font-size: 14px; }
	// .main-pick .sidebar
	// 	margin-top: 60px
	.sidebar {
		margin-left: 0;
		a {
			color: #343434;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			text-decoration: none;
			margin-bottom: 10px; } }
	.main-pick .sidebar {
		min-height: auto; }


	.main-pick-content:after {
		width: 80%;
		height: 100%;
		bottom: -15.5%;
		opacity: 0.5;
		left: 35.1%;
		background-size: contain; }
	.main-pick-content {
		padding-left: 0; }
	.main-advantages-item-name {
		font-size: 19px; }
	.content p, .main-buy-list a {
		font-size: 16px; }
	.sidebar-allcategory {
		margin-left: -20px;
		padding-left: 20; }
	.sidebar-form {
		margin-left: 0; }
	.sidebar-layout {
		padding-left: 0; }
	.catalog-list-item {
		width: 100%; } }
@include r(767) {
	.menu-down-list {
		ul {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			padding: 10px 0;
			a {
				margin-bottom: 5px;
				display: inline-block; } } }
	.sidebar-layout-view {
		margin: 30px 0 0; }
	.mdn {
		display: none !important; }
	.header-logo, .header-contacts {
		text-align: center; }
	.header-nav {
		display: none; }
	.main-buy-title, .main-advantages-title, .main-pick-title, .main-content-title {
		font-size: 24px; }
	.footer-right {
		text-align: center;
		margin-top: 20px; }
	.main-buy-list {
		text-align: center;
		display: block;
		li {
			margin-bottom: 10px; } }
	.main-buy-title {
		margin-bottom: 40px; }
	.header-mobile-toggle {
		display: flex; }
	.product-list-item--row .catalog-list-item__name .product-card__total {
		text-align: left; }
	.product-list-item--row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap; }
	.product-list-item--row .catalog-list-item__photo {
		max-width: 100%; } }

.category {

	&-visual {
		.category-filter-item {
			min-width: 192px; } }
	&-filter {
		margin-bottom: 36px;
		&-item {
			font-size: 16px;
			display: inline-flex;
			margin-right: 20px;
			min-height: 42px;
			background: #e1e1e1;
			border-radius: 10px;
			-webkit-align-items: center;
			align-items: center;
			padding: 7px 40px;
			@include r(767) {
				padding: 7px 25px;
				margin-right: 10px; }
			-webkit-justify-content: space-between;
			justify-content: space-between;
			&:last-child {
				margin-right: 0; }
			.category-filter-item-view {
				height: 26px;
				width: 28px;
				transition: transform 200ms ease;
				&:hover {
					transform: scale(1.1); }
				svg {
					fill: #4c4c4c;
					display: block;
					width: 100%;
					height: 100%; }
				&.active {
					svg {
						fill: #dc4040; } } }
			.select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single {
				border: 0; }
			.select2-dropdown .select2-dropdown--below {
				border-top: 1px solid #aaa !important; }
			.select2-container--classic .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--single .select2-selection__arrow b {
				// background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI4NC45MjkgMjg0LjkyOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg0LjkyOSAyODQuOTI5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGc+Cgk8cGF0aCBkPSJNMjgyLjA4Miw3Ni41MTFsLTE0LjI3NC0xNC4yNzNjLTEuOTAyLTEuOTA2LTQuMDkzLTIuODU2LTYuNTctMi44NTZjLTIuNDcxLDAtNC42NjEsMC45NS02LjU2MywyLjg1NkwxNDIuNDY2LDE3NC40NDEgICBMMzAuMjYyLDYyLjI0MWMtMS45MDMtMS45MDYtNC4wOTMtMi44NTYtNi41NjctMi44NTZjLTIuNDc1LDAtNC42NjUsMC45NS02LjU2NywyLjg1NkwyLjg1Niw3Ni41MTVDMC45NSw3OC40MTcsMCw4MC42MDcsMCw4My4wODIgICBjMCwyLjQ3MywwLjk1Myw0LjY2MywyLjg1Niw2LjU2NWwxMzMuMDQzLDEzMy4wNDZjMS45MDIsMS45MDMsNC4wOTMsMi44NTQsNi41NjcsMi44NTRzNC42NjEtMC45NTEsNi41NjItMi44NTRMMjgyLjA4Miw4OS42NDcgICBjMS45MDItMS45MDMsMi44NDctNC4wOTMsMi44NDctNi41NjVDMjg0LjkyOSw4MC42MDcsMjgzLjk4NCw3OC40MTcsMjgyLjA4Miw3Ni41MTF6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMwMDlBMjIiIGRhdGEtb2xkX2NvbG9yPSIjMDA5YTIyIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==)
				width: 100%;
				height: 100%;
				display: block;
				border: none;
				position: static;
				margin: 0;
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat; }
			.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b, .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
				// background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI4NC45MjkgMjg0LjkyOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg0LjkyOSAyODQuOTI5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGc+Cgk8cGF0aCBkPSJNMjgyLjA4MiwxOTUuMjg1TDE0OS4wMjgsNjIuMjRjLTEuOTAxLTEuOTAzLTQuMDg4LTIuODU2LTYuNTYyLTIuODU2cy00LjY2NSwwLjk1My02LjU2NywyLjg1NkwyLjg1NiwxOTUuMjg1ICAgQzAuOTUsMTk3LjE5MSwwLDE5OS4zNzgsMCwyMDEuODUzYzAsMi40NzQsMC45NTMsNC42NjQsMi44NTYsNi41NjZsMTQuMjcyLDE0LjI3MWMxLjkwMywxLjkwMyw0LjA5MywyLjg1NCw2LjU2NywyLjg1NCAgIGMyLjQ3NCwwLDQuNjY0LTAuOTUxLDYuNTY3LTIuODU0bDExMi4yMDQtMTEyLjIwMmwxMTIuMjA4LDExMi4yMDljMS45MDIsMS45MDMsNC4wOTMsMi44NDgsNi41NjMsMi44NDggICBjMi40NzgsMCw0LjY2OC0wLjk1MSw2LjU3LTIuODQ4bDE0LjI3NC0xNC4yNzdjMS45MDItMS45MDIsMi44NDctNC4wOTMsMi44NDctNi41NjYgICBDMjg0LjkyOSwxOTkuMzc4LDI4My45ODQsMTk3LjE4OCwyODIuMDgyLDE5NS4yODV6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMwMDlBMjIiIGRhdGEtb2xkX2NvbG9yPSIjMDA5YTIyIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==)
				width: 100%;
				height: 100%;
				display: block;
				border: none;
				position: static;
				margin: 0;
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat; } } } }
.select2-results__option--highlighted {
	background-color: #565656 !important; }
.select2-container {
	min-width: 145px;
	text-align: left; }
.select2-container--default .select2-results__option[aria-selected=true] {
	color: #FFF;
	background-color: #dc4040; }
.filter-modal {
	position: absolute;
	z-index: 10;
	display: none;
	top: calc(100% + 20px);
	left: 0;
	color: #fff;
	min-width: 498px;
	@include r(767) {
		min-width: 290px; }
	max-width: 100%;
	width: 100%;
	padding: 10px 25px;
	background-color: #4c4c4c;
	.btn {
		display: inline-flex;
		cursor: pointer;
		padding: 7px 40px;
		font-size: 16px; }
	.filter-close {
		padding: 7px 40px;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 15px; }
	.filter-line {
		position: relative; }
	input[type=checkbox] {
	  + label {
	    font-size: 16px;
	    display: block;
	    margin-bottom: 15px;
	    cursor: pointer;
	    padding-left: 25px;
	    &:before {
	      content: "";
	      background: #FFF;
	      border: 0.1em solid #fff;
	      border-radius: 0.2em;
	      display: inline-block;
	      position: absolute;
	      left: 0;
	      top: 2px;
	      width: 1em;
	      height: 1em;
	      padding-left: 0.2em;
	      padding-bottom: 0.3em;
	      margin-right: 10px;
	      vertical-align: bottom;
	      color: transparent;
	      transition: .2s; }
	    &:active:before {
	      transform: scale(0); } }
	  display: none;
	  &:checked + label:before {
	    background-color: #dc4040;
	    border-color: #dc4040;
	    color: #fff; }
	  &:disabled + label:before {
	    transform: scale(1);
	    border-color: #aaa; }
	  &:checked:disabled + label:before {
	    transform: scale(1);
	    background-color: #bfb;
	    border-color: #bfb; } }
	&.active {
		display: block; }
	border-radius: 10px; }
.filter-title {
	margin-bottom: 20px;
	padding-top: 15px;
	font-size: 18px;
	font-weight: 600; }
.filter-light {
	background-color: #c7c7c7;
	border-radius: 10px;
	padding: 15px 20px;
	min-height: 320px;
	color: #303030;
	.filter-title {
		padding-top: 0; }
	margin-bottom: 20px; }

.category-filter-item--sort {
	padding-left: 5px !important;
	padding-right: 5px !important;
	.select {
		width: 100%;
		text-align: center;
		.select2-selection__rendered {
			padding-right: 0px;
			background: #e1e1e1;
			color: #000; } } }
.select2-results__option {
    padding: 5px 7px; }
.category-filter-wrapper {
	position: relative;
	display: inline-block; }
.category-filter-item--filter {
	cursor: pointer;
	background: #e1e1e1;
	color: #000;
	div {
		margin-right: 5px; }
	svg {
		fill: #dc4040;
		width: 11px;
		height: 11px; }
	&.active {
		background: #4c4c4c;
		color: #FFF; } }
.product-card-short {
	margin-top: 40px;
	&__item {
		margin-bottom: 10px; } }
.sidebar-layout-title {
	@include r(767) {
		text-align: center; } }

.header-social {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	a {
		margin-left: 5px; }
	@include r(767) {
		justify-content: center; } }
