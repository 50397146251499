@charset "UTF-8";

// Default Variables

$slick-font-path: "../fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}

.slick-prev:before, .slick-next:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

// .slick-slider {
//     margin-bottom: 30px;
// }

.slick-dots {
    position: absolute;
    bottom: 22px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        cursor: pointer;
        button {
            display: block;
            padding: 3px;
            background: none;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: red;
            cursor: pointer;
            &:before {
                content: "";
                display: block;
                height: 10px;
                width: 10px;
                box-sizing: border-box;
                border-radius: 50%;
                background: #979795;
                transition: all 0.1s ease;
            }
            &:hover {
                &:before {
                   background: #4E4E4E;
                }
            }
        }
        &.slick-active button:before {
            background: #4E4E4E;
        }
    }

}